import React from "react";
import { PrimaryButton } from "../components/Button";
import Layout from "../components/layout/Layout";
import PricePlan from "../components/pricing/PricePlan";
import { Helmet } from "react-helmet";

export default function Pricing({ data }) {
  const pricingInfo = data.allPricingInfoJson.nodes;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pricing | Baseline</title>
        <meta
          name="description"
          content="Baseline is an efficient and cost effective resource planning tool that builds and manages the optimal teams for your projects."
        />
        <meta
          name="keywords"
          content="Saas, Cheap, Time Saving ,Team, Team Management,Mental Health,Best Team Management Tool, Best Project Management Tool,Easy to use Team Management Tool,Easy to use Project Management Tool,Monitoring profitability,Ways to improve profitability,Time management,How to improve mental health,How to build a successful team,Staffing tool,Utilization tool,HR tool or HR Software,Resource allocation,People first team creation,Hybrid workforce,Visualize department utilization,Collaborative team building,Hard and Soft skill data talent search,User friendly team management,Future workforce,Creating happy and profitable teams,How can I see burnout in my team,"
        />
        <link rel="canonical" href="https://baselineteams.com" />
      </Helmet>

      <Layout>
        <div className="mx-auto px-4 py-8 text-center bg-primary-off-white md:px-40 lg:px-40 lg:py-16">
          <div className="text-4xl font-bold">Simple pricing</div>
          <div className="text-base my-6 lg:px-32">
            One pricing plan for everything. Pay for active users who manage
            people, projects and clients and a minimal price for everyone else.
          </div>
          <div className="hidden text-base my-6 lg:block lg:px-32">
            Start now to get{" "}
            <span className="text-red text-xl font-bold">
              the first 2 months free
            </span>{" "}
            and{" "}
            <span className="text-pink text-xl font-bold">
              no fee for all viewers the first year
            </span>
          </div>
          <div className="my-2 text-center lg:hidden">
            <div className="text-base">Start now to get</div>
            <div className="text-red text-xl font-bold mt-1">
              the first 2 months free
            </div>{" "}
            and{" "}
            <div className="text-pink text-xl font-bold">
              no fee for all viewers the first year
            </div>
          </div>

          <div className="flex flex-col justify-center items-center lg:flex-row">
            {pricingInfo.map((pricePlan) => (
              <PricePlan key={pricePlan.id} pricePlan={pricePlan}></PricePlan>
            ))}
          </div>

          <div className="my-8">
            If you are looking for SSO or additional 3rd party login
            authentication options, please{" "}
            <a href="/contact-us/" className="text-primary-blue-hover">
              contact us
            </a>
            .
          </div>
          <div className="my-8">
            <a href="https://app.baselineteams.com/register">
              <PrimaryButton>Start for free now</PrimaryButton>
            </a>
          </div>
        </div>
      </Layout>
    </>
  );
}
export const query = graphql`
  query PricingInfoQuery {
    allPricingInfoJson {
      nodes {
        id
        payment
        activeFee
        viewerFee
        discount
      }
    }
  }
`;

import React from "react"

const PricePlan = ({ pricePlan }) => {
  console.log(pricePlan.payment)
  return (
    <div className="relative w-full min-w-max flex-1 py-8 px-12 shadow-md bg-white rounded-md my-6 mx-0 text-left lg:mx-20">
      {pricePlan.discount && (
        <div className="absolute top-3 right-3 text-sm font-semibold text-dark-gray">
          Save {pricePlan.discount * 100}%
        </div>
      )}
      <div className="text-lg text-black font-semibold">
        {pricePlan.payment}
      </div>

      <div className="text-4xl text-primary-blue font-bold mt-6">
        {pricePlan.activeFee}
        <span className="text-lg text-primary-blue font-bold ml-4">USD</span>
      </div>

      <div className="text-sm text-black font-light mt-1">
        per active user per month
      </div>
      <div className="text-4xl text-green font-bold mt-6">
        <span className="line-through">{pricePlan.viewerFee}</span>
        <span className="text-lg text-green font-bold ml-4">USD</span>
        <span className="text-sm text-pink font-medium ml-4">
          First year free
        </span>
      </div>

      <div className="text-sm text-black font-light mt-1">
        per viewer per month
      </div>
    </div>
  )
}
export default PricePlan
